.animation-logo {
    width: 200px;
.animated-groupe{
animation: dotAnimation 2s linear 0s infinite forwards;
transform-origin: 27.5px 27.5px;

}
    .dot-tail-group {
        transform-origin: 27.5px 27.5px;
        .tail {
            fill: white;
        }

        .dot {
            fill: #16F2B3;
        }
    }
}

@keyframes dotAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }

}