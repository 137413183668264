@tailwind base;
@tailwind components;
@tailwind utilities;
@import './preloader';

html {
  scroll-behavior: smooth;
  width: 100%;
  overflow-x: hidden;
}

//SignIm
.contain {
  background-image: url("../images/bga.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.sign {
  height: 120px;
  width: 250px;
  background: linear-gradient(180deg, #E93B77 0%, #DA1F63 100%);
  position: absolute;
  top: 200px;
  z-index: 10;
  box-shadow: 0px 2px 6px rgba(220, 34, 101, 0.36);
  border-radius: 8px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: all 0.2s;
}

.sign p {
  margin: 10px 0px;
}

.icons a {
  padding: 0px 5px;
}

.sign img:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.sign img:active {
  transform: translateY(1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.box {
  height: 350px;
  width: 280px;
  background-color: white;
  position: absolute;
  top: 230px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.form {
  margin-top: 100px;
  color: #7B809A;
}

.form input {
  width: 250px;
  height: 20px;
  padding: 15px;
  margin-top: 10px;
  margin-left: 8px;
  border: 1px solid #C7CCD0;
  border-radius: 8px;
}

.form input:hover {
  border: 2px solid #DA1F63;
}

.lab {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.lab p {
  margin: 0px 5px;
  padding: 0px;
}

input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 30px;
  height: 15px;
  background: grey;
  display: block;
  border-radius: 30px;
  position: relative;
}

label:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 15px;
  height: 15px;
  background: rgb(252, 245, 245);
  border-radius: 20px;
  transition: 0.3s;
}

input:checked+label:after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.btn {
  width: 270px;
  height: 30px;
  background: linear-gradient(180deg, #E93B77 0%, #DA1F63 100%);
  border-radius: 8px;
  border: 1px solid #DA1F63;
  margin-left: 5px;
  margin-top: 20px;
  transition: all 0.2s;
  text-decoration: none;
  color: white;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.signup {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.signup a {
  text-decoration: none;
  color: #E93B77;
}

// Service Card img animation
.service:hover img {
  translate: 0px -4px;
  transition: ease-in;
  transition-duration: 0.2s;
  animation-name: simg;
  animation-duration: 0.2s;
  animation-timing-function: linear;
}

@keyframes simg {
  0% {
    translate: 0px -8px;
  }

  100% {
    translate: 0px -4px;
  }
}

// arrow animatio
button:hover #arrow {
  animation-name: arrow;
  animation-duration: 0.5s;
  animation-timing-function: linear;
}

// .DraftEditor-editorContainer{
//   min-height:250px;
// }
.public-DraftStyleDefault-block {
  min-height: 250px;
  border: 1px solid #f3f4f6;
}

.custom-tiles {
  &>div {

    @media(max-width:1024px) {
      border-right: 1px solid #dbd7d7;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        border-bottom: 1px solid #dbd7d7;
      }

      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(6) {
        border-right: none;
      }
    }

    @media(min-width:1024px) {
      border-right: 1px solid #dbd7d7;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        border-bottom: 1px solid #dbd7d7;
      }

      &:nth-child(3),
      &:nth-child(6) {
        border-right: none;
      }
    }

    @media(max-width:768px) {
      border-right: none;
      border-bottom: 1px solid #dbd7d7;

      &:nth-child(6) {
        border-bottom: none;
      }
    }
  }
}

.service-img {
  width: 0;
  height: 0;
  padding: 40px;
  position: relative;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;

  .customFIlter {
    position: absolute;
    top: 0px;
    left: 0px;
    filter: invert(13%) sepia(8%) saturate(7239%) hue-rotate(182deg) brightness(98%) contrast(107%);
  }

}

@keyframes arrow {
  0% {
    translate: 5px 0px;
  }

  100% {
    translate: 0px 0px;
  }
}

.swiper-button-next,
.swiper-button-prev {
  background-color: white;
  width: 35px !important;
  height: 35px !important;
  border-radius: 20px;

  &::after {
    font-size: 1.25rem !important;
    filter: brightness(0.5);
  }
}

.addPatthern {
  background-image: url('../images/White\ Simple\ Minimalist\ Pattern\ Zoom\ Virtual\ Background\ .png');
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: cover;
}

#nav {
  transition: all 0.5s ease;
}